import { get as _get } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useOnScreen } from '../../../../customHooks';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { useAutoCorrelation } from '../fftTwf/useAutoCorrelation';
import { useBands } from '../fftTwf/useBands';
import { useBearings } from '../fftTwf/useBearings';
import { useCircleType } from '../fftTwf/useCircleType';
import { useEnvelope } from '../fftTwf/useEnvelope';
import { useFFDetection } from '../fftTwf/useFFDetection';
import { useFaultFrequencies } from '../fftTwf/useFaultFrequencies';
import { useCustomCursorPointsCount, useFftCursor, useHarmoniCursorByPoint } from '../fftTwf/useFftCursor';
import { useFftTwfSizeChartsEffect } from '../fftTwf/useFftTwfSizeCharts';
import { useLoader } from '../fftTwf/useLoader';
import { useLockedCursor } from '../fftTwf/useLockedCursor';
import { useMainSeriesLoaded } from '../fftTwf/useMainSeriesLoaded';
import { usePlotlines } from '../fftTwf/usePlotlines';
import { useResetCursorsOnAllCharts } from '../fftTwf/useResetCursorsOnAllCharts';
import { useResetCursorsOnChart } from '../fftTwf/useResetCursorsOnChart';
import { useSetOptions } from '../fftTwf/useSetOptions';
import { useUnits } from '../fftTwf/useUnits';
import { useAxisLabel } from '../shared/useAxisLabel';
import { useMessageOnChart } from '../shared/useMessageOnChart';
import { useResetChartSelection } from '../shared/useResetChartSelection';
import { useResetToolsToDefault } from '../shared/useResetToolsToDefault';
import { useSelectProblemChart } from '../shared/useSelectProblemChart';
export var useFftTwfHooks = function (state, dispatch, chartIdentifier, chartRef, chartContainerRef) {
    var _a;
    var _b = FftChartsStore(function (state) { return ({
        loader: state.loader,
        readings: state.readings,
        data: state.data,
        faultFrequencies: state.faultFrequencies,
        fftAlerts: state.fftAlerts,
        fftTimestampDates: state.fftTimestampDates,
        isChangedCalendar: state.isChangedCalendar,
        fftSelectedDate: state.fftSelectedDate,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
    }); }, shallow), loader = _b.loader, readings = _b.readings, data = _b.data, fftAlerts = _b.fftAlerts, fftTimestampDates = _b.fftTimestampDates, isChangedCalendar = _b.isChangedCalendar, fftSelectedDate = _b.fftSelectedDate, setStatesFftChartsStore = _b.setStatesFftChartsStore;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var interpolationDisabled = ChartFeaturesStore(function (state) { return ({
        interpolationDisabled: state.interpolationDisabled,
    }); }, shallow).interpolationDisabled;
    var isVisible = useOnScreen(chartContainerRef, [
        installationPoint.id,
        (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.settings) === null || _a === void 0 ? void 0 : _a.high_pass,
        interpolationDisabled,
        fftSelectedDate,
        _get(data, 'sensor.is_hfdvue'),
    ]);
    useEffect(function () {
        chartRef.current.chart.userOptions.FFDetectionPoints = state.FFDetectionPoints;
    }, [state.FFDetectionPoints]);
    useMainSeriesLoaded(state, dispatch, chartIdentifier, loader, readings);
    useSetOptions(state, dispatch, chartIdentifier, chartRef, loader, data, fftTimestampDates, readings, isVisible, fftAlerts);
    useBands(state, dispatch, chartRef, chartIdentifier);
    useEnvelope(state, dispatch, chartRef, chartIdentifier);
    useAutoCorrelation(state, dispatch, chartIdentifier, loader, fftTimestampDates, isChangedCalendar, fftSelectedDate);
    useBearings(state, chartRef, chartIdentifier, loader);
    useFaultFrequencies(state, chartRef, chartIdentifier, loader);
    useResetCursorsOnAllCharts(state, chartIdentifier, loader, fftTimestampDates, isChangedCalendar, fftSelectedDate, chartRef);
    useResetChartSelection(dispatch);
    useUnits(state, chartRef, loader);
    useFftCursor(state, loader, chartRef);
    useCustomCursorPointsCount(loader, chartRef, state);
    useHarmoniCursorByPoint(loader, chartRef, chartIdentifier);
    useLockedCursor(state, chartRef);
    useLoader(state, setStatesFftChartsStore);
    useMessageOnChart(state, chartRef);
    usePlotlines(state, chartRef, loader);
    useResetToolsToDefault(state, chartRef);
    useSelectProblemChart(state, dispatch, chartIdentifier, chartRef);
    useFftTwfSizeChartsEffect(state, dispatch, loader);
    useResetCursorsOnChart(state, chartRef);
    useCircleType(state, dispatch, chartIdentifier, readings, data);
    useAxisLabel(state, dispatch, chartIdentifier);
    useFFDetection(state, dispatch, chartRef);
};
