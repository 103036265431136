import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import { memo, useMemo } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { BEARING_TYPES } from '../../../../../../../constants';
import { FftChartsStore } from '../../../../../../store/charts/chartsContent/FftChartsStore';
import DataTable from '../../../../../shared/Table/Table';
import { FrequencyConverter } from '../../../features/fftTwf/frequencyConverter';
export var FFDetectionTable = memo(function (_a) {
    var FFDetectionPoints = _a.FFDetectionPoints, currentSpeed = _a.currentSpeed, precision = _a.precision, units = _a.units, dispatch = _a.dispatch, options = _a.options;
    var installationPointBearings = FftChartsStore(function (state) { return state.installationPointBearings; });
    var faultFrequencies = FftChartsStore(function (state) { return state.faultFrequencies; });
    var stepRange = currentSpeed
        ? FrequencyConverter.fromOrders(0.1, currentSpeed).toHz().value
        : (options.fmax / options.lor) * 2;
    var peaks = useMemo(function () {
        var arr = [];
        installationPointBearings.forEach(function (bearingInstallationPoint) {
            BEARING_TYPES.forEach(function (bearingType) {
                for (var i = 1; i <= bearingInstallationPoint.plot_lines_count; i++) {
                    var value = FrequencyConverter.fromOrders(bearingInstallationPoint.bearing[bearingType], currentSpeed).toHz().value * i;
                    if (value === 0) {
                        continue;
                    }
                    arr.push([bearingType.toUpperCase(), value, i]);
                }
            });
        });
        faultFrequencies.forEach(function (faultFrequency) {
            if (currentSpeed <= 0 && faultFrequency.frequency_type === 'Orders') {
                return;
            }
            var value = FrequencyConverter.fromType(faultFrequency.frequency_type, faultFrequency.frequency, currentSpeed).toHz().value;
            for (var i = 1; i <= _get(faultFrequency, 'plot_lines_count'); i++) {
                arr.push([faultFrequency.name, value * i, i]);
            }
        });
        for (var i = 1; i <=
            Math.round(FrequencyConverter.fromHz(options.series[0].data[options.series[0].data.length - 1][0], currentSpeed).toOrders().value); i++) {
            arr.push(['', FrequencyConverter.fromOrders(i, currentSpeed).toHz().value, i]);
        }
        return arr;
    }, [installationPointBearings, faultFrequencies]);
    var getNearestPoints = function (x) {
        return peaks
            .filter(function (_a) {
            var type = _a[0], val = _a[1], i = _a[2];
            return x + stepRange > val && x - stepRange < val && !(type === 'ftf' && i > 5);
        })
            .map(function (el) {
            return [el[2] + 'x' + ' ' + el[0]];
        });
    };
    var onRemove = function (value, isAllRemove) {
        dispatch({
            type: 'setState',
            state: {
                FFDetectionPoints: isAllRemove ? [] : FFDetectionPoints.filter(function (_, index) { return index !== value; }),
            },
        });
    };
    return (_jsx("div", { style: {
            height: 'calc(40vh)',
            overflow: 'hidden',
            overflowY: 'auto',
        }, children: _jsx(DataTable, { config: {
                columns: [
                    {
                        name: 'identifier',
                        title: 'Identifier',
                        sortable: false,
                    },
                    {
                        name: 'frequency',
                        title: 'Frequency',
                        sortable: false,
                    },
                    {
                        name: 'difference',
                        title: 'Difference',
                        sortable: false,
                    },
                    {
                        name: 'order',
                        title: 'Order',
                        sortable: false,
                    },
                    {
                        name: 'fault_frequency',
                        title: 'Fault Frequency',
                        sortable: false,
                    },
                    {
                        name: 'value',
                        title: 'Value',
                        sortable: false,
                    },
                    {
                        titleComponent: function (el) {
                            if (!FFDetectionPoints.length) {
                                return;
                            }
                            return (_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { tag: "div", onClick: function () { return onRemove(el.index, true); }, color: "danger", size: "sm", children: "Remove all" }) }));
                        },
                        name: 'actions',
                        sortable: false,
                        component: function (el) {
                            return (_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { tag: "div", onClick: function () { return onRemove(el.index); }, color: "danger", size: "sm", children: "Remove" }) }));
                        },
                    },
                ],
                disabledSearch: true,
                pagination: {
                    disabled: true,
                },
                hideTotalLabel: true,
                loader: false,
                data: FFDetectionPoints.map(function (x, index) {
                    var value = x.toFixed(2);
                    if (units === 'CPM') {
                        value = FrequencyConverter.fromHz(x, currentSpeed).toCpm().numberFormat(precision);
                    }
                    var nearestPoints = getNearestPoints(x);
                    var difference = index ? +value - FFDetectionPoints[index - 1] : null;
                    var order = currentSpeed > 0
                        ? FrequencyConverter.fromHz(x, currentSpeed).toOrders().value.toFixed(2)
                        : '';
                    return {
                        identifier: 'C' + (index + 1),
                        frequency: value,
                        difference: Math.abs(difference !== null && difference !== void 0 ? difference : 0).toFixed(2),
                        order: order,
                        value: options.series[0].data.find(function (el) { return el[0] === x; })[1],
                        fault_frequency: nearestPoints.join(', '),
                        index: index,
                    };
                }).reverse(),
            } }) }));
});
