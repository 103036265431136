import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../../api/api';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { getCoreUrl } from '../../../helper/commonHelper';
var analystNotesDeleteFn = function (coreLink, id) {
    return Api.post("".concat(coreLink, "/analyst-notes/delete/").concat(id), {});
};
export var useAnalystNotesDeleteMutation = function () {
    var queryClient = useQueryClient();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (id) { return analystNotesDeleteFn(getCoreUrl(selectedSystemType), id); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['analystNotesList'], exact: false });
        },
    });
};
