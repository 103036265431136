var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get as _get } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { isTwfChart } from '../../../helper/chart';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useCircleType = function (state, dispatch, chartIdentifier, readings, data) {
    var currentSpeed = InstallationPointStore(function (state) { return ({
        currentSpeed: state.currentSpeed,
    }); }, shallow).currentSpeed;
    var isVisibleFullscreenChart = state.isVisibleFullscreenChart, options = state.options, isCircleTwfType = state.isCircleTwfType, isFFDetection = state.isFFDetection;
    var cycleTime = 60 / (currentSpeed || 1);
    var chartView = ChartsStore(function (state) { return ({
        chartView: state.chartView,
    }); }, shallow).chartView;
    var readingsData = _get(readings, chartIdentifier);
    useEffect(function () {
        if (!isTwfChart(chartIdentifier)) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { series: options.series.map(function (seriesItem) {
                        var _a;
                        var cycleIteration = 0;
                        return __assign(__assign({}, seriesItem), { data: isTwfChart(chartIdentifier) && isCircleTwfType && ((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length)
                                ? __spreadArray([], ((seriesItem.id === "fft_".concat(chartIdentifier, "_").concat(_get(data, 'installationPoint.id'))
                                    ? readingsData
                                    : _get(data, 'auto_correlation.' + chartIdentifier)) || []), true).map(function (point) {
                                    if (point[0] >= cycleTime + cycleIteration * cycleTime) {
                                        cycleIteration++;
                                    }
                                    var x = (point[0] - cycleIteration * cycleTime) / cycleTime;
                                    return [x * 360, point[1]];
                                })
                                : seriesItem.id === "fft_".concat(chartIdentifier, "_").concat(_get(data, 'installationPoint.id'))
                                    ? readingsData
                                    : seriesItem.id ===
                                        "fft_auto_correlation_".concat(chartIdentifier, "_").concat(_get(data, 'installationPoint.id'))
                                        ? _get(data, 'auto_correlation.' + chartIdentifier)
                                        : seriesItem.data });
                    }), xAxis: [
                        __assign(__assign({}, options.xAxis[0]), { min: isCircleTwfType && !isFFDetection ? 0 : null, max: isCircleTwfType ? 360 : null, tickInterval: isCircleTwfType ? 30 : null }),
                    ], yAxis: [
                        __assign(__assign({}, options.yAxis[0]), { title: {
                                rotation: -90,
                                x: isCircleTwfType && !isFFDetection
                                    ? isVisibleFullscreenChart
                                        ? -700
                                        : chartView === 'list'
                                            ? -200
                                            : -120
                                    : null,
                            }, labels: {
                                align: 'left',
                                x: isCircleTwfType && !isFFDetection
                                    ? isVisibleFullscreenChart
                                        ? -690
                                        : chartView === 'list'
                                            ? -190
                                            : -110
                                    : null,
                            } }),
                    ], chart: __assign(__assign({}, options.chart), { polar: isCircleTwfType && !isFFDetection }) }),
            },
        });
    }, [isCircleTwfType]);
};
