import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTimeLabel, DateTimeLabelWithUTC } from '../../../../widgets/common';
export var dataTableConfig = {
    columns: [
        {
            name: 'user_name',
            title: 'Added By',
            sortable: false,
        },
        {
            name: 'created_at',
            title: 'Date Create',
            sortable: false,
            component: function (_a) {
                var created_at = _a.created_at;
                return _jsx(DateTimeLabel, { dateTime: created_at });
            },
        },
        {
            name: 'updated_at',
            title: 'Date Update',
            sortable: false,
            component: function (_a) {
                var updated_at = _a.updated_at;
                return _jsx(DateTimeLabel, { dateTime: updated_at });
            },
        },
    ],
    data: [],
    loader: false,
    hideTotalLabel: true,
    pagination: {
        disabled: true,
    },
    search: '',
    disabledSearch: true,
    getIsShowAdditionContent: function () { return true; },
    additionContent: function (_a) {
        var text = _a.text, user_name = _a.user_name, created_at = _a.created_at;
        return (_jsxs("div", { className: "table-additional-content", children: ["Install App Note", _jsx("br", {}), _jsx("b", { children: "Description: " }), _jsx("span", { dangerouslySetInnerHTML: { __html: text } }), _jsx("br", {}), _jsx("b", { children: "Added by: " }), user_name, _jsx("br", {}), _jsx("b", { children: "Date create: " }), _jsx(DateTimeLabelWithUTC, { dateTime: created_at })] }));
    },
};
