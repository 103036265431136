var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import DataTable from '../../../../shared/components/shared/Table/Table';
import { useSSDNotesQuery } from '../../../services/notes/useSSDNotesQuery';
import { dataTableConfig } from './dataTableConfig';
export var SSDNotes = function (_a) {
    var equipmentId = _a.equipmentId;
    var _b = useSSDNotesQuery({ equipmentId: equipmentId }), data = _b.data, isFetching = _b.isFetching;
    return (_jsx(DataTable, { isLoading: isFetching, config: __assign(__assign({}, dataTableConfig), { data: data }) }));
};
