var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Highcharts from 'highcharts';
import { range as _range, size as _size } from 'lodash';
import { getNearestPointIndex, isFftTwfChart, isTwfChart, onBlockedFftTwfMouseMove, onBlockedRmsMouseMove, } from '../../../../../helper/chart';
import { removeAllByClassName } from '../../../../../helper/dom';
import { FrequencyConverter } from '../fftTwf/frequencyConverter';
export var toggleRmsCursor = function (setStatesChartStore, isLockedRmsCursor, chart) {
    if (!chart) {
        return;
    }
    onBlockedRmsMouseMove(chart, isLockedRmsCursor);
    setStatesChartStore({
        isLockedRmsCursor: !isLockedRmsCursor,
    });
};
export var lockCursor = function (chart, chartRef, dispatch) {
    if (chartRef.current.chart.userOptions.chartCursorType === 'FFDetection' && dispatch) {
        if (!chartRef.current.chart.userOptions.FFDetectionPoints) {
            chartRef.current.chart.userOptions.FFDetectionPoints = [];
        }
        chartRef.current.chart.userOptions.FFDetectionPoints = __spreadArray(__spreadArray([], chartRef.current.chart.userOptions.FFDetectionPoints, true), [
            chart.hoverPoint.x,
        ], false);
        return dispatch({
            type: 'setState',
            state: {
                FFDetectionPoints: chartRef.current.chart.userOptions.FFDetectionPoints,
            },
        });
    }
    if (chart.userOptions.chartCursorType === 'normal') {
        chartRef.current.chart.userOptions.isLockedCursor = !chartRef.current.chart.userOptions.isLockedCursor;
        onBlockedFftTwfMouseMove(!chartRef.current.chart.userOptions.isLockedCursor, chart);
    }
    if (chart.userOptions.chartCursorType === 'harmonic') {
        chart.userOptions[chart.altIsPressed ? 'secondHarmonicPoints' : 'harmonicPoints'] = chart.hoverPoints;
    }
    toggleFftCursor(chartRef, chart.altIsPressed);
    if (chart.userOptions.chartCursorType === 'sideband' && !chart.userOptions.isLockedFftSidebandLineCursor) {
        chart.userOptions.sideBandPoint = chart.hoverPoint;
    }
    if (chart.userOptions.isLockedFftSidebandLineCursor) {
        chart.userOptions.sideBandSecondHoverPoint = chart.hoverPoint;
    }
};
export var toggleFftCursor = function (chartRef, altIsPressed) {
    if (altIsPressed === void 0) { altIsPressed = false; }
    var chart = chartRef.current.chart;
    var userOptions = chart.userOptions;
    if (altIsPressed) {
        userOptions.isLockedWithAltFftCursor = !userOptions.isLockedWithAltFftCursor;
        return;
    }
    if (userOptions.chartCursorType === 'sideband') {
        if (userOptions.isLockedFftCursor) {
            userOptions.isLockedFftCursor = true;
            userOptions.isLockedFftSidebandLineCursor = !userOptions.isLockedFftSidebandLineCursor;
            userOptions.lockedFftSidebandLinePoint = chart.hoverPoint;
        }
        else {
            userOptions.isLockedFftCursor = true;
            userOptions.isLockedFftSidebandLineCursor = false;
            userOptions.lockedFftSidebandLinePoint = null;
        }
    }
    else {
        userOptions.isLockedFftCursor = !userOptions.isLockedFftCursor;
    }
};
export var initMovableTooltip = function () {
    $(document).off('mousedown', '.custom_tooltip_locker');
    $(document).on('mousedown', '.custom_tooltip_locker', function (ev) {
        var chart = {};
        // @ts-ignore
        var chartIdentifier = $(ev.currentTarget).closest('.chart-item').data('chartIdentifier');
        Highcharts.charts.forEach(function (item) {
            if (item) {
                if (+item.userOptions.chartIdentifier === +chartIdentifier) {
                    chart = item;
                }
            }
        });
        // @ts-ignore
        var element = $(ev.currentTarget).closest('.highcharts-tooltip');
        var pos1 = 0;
        var pos2 = 0;
        var pos3 = ev.clientX;
        var pos4 = ev.clientY;
        var newPosX = 0;
        var newPosY = 0;
        // @ts-ignore
        $(document).on('mouseup', function () {
            // @ts-ignore
            $(document).off('mouseup');
            // @ts-ignore
            $(document).off('mouseover');
        });
        // @ts-ignore
        $(document).on('mouseover', function (e) {
            var _a;
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            newPosX = parseInt(element.css('left')) - pos1;
            newPosY = parseInt(element.css('top')) - pos2;
            element.css('position', 'absolute');
            var tooltipWidth = (_a = element === null || element === void 0 ? void 0 : element.find('.custom_tooltip').parent().width()) !== null && _a !== void 0 ? _a : 0 - 100;
            if (chart.plotWidth - tooltipWidth < newPosX) {
                newPosX = chart.plotWidth - tooltipWidth;
            }
            if (chart.plotHeight < newPosY) {
                newPosY = chart.plotHeight;
            }
            element.css('left', newPosX + 'px');
            element.css('top', newPosY + 'px');
        });
    });
};
export var cursorMouseOverEventHandler = function (chart, currentSpeed, customCursorPointsCount, altIsPressed) {
    if (altIsPressed === void 0) { altIsPressed = false; }
    if (!chart.hoverPoints || chart.userOptions.isCircleTwfType) {
        return;
    }
    if (chart.userOptions.chartCursorType === 'harmonic') {
        if (altIsPressed) {
            secondHarmonicCursor(chart.hoverPoints, customCursorPointsCount);
        }
        else {
            harmonicCursor(chart.hoverPoints, customCursorPointsCount);
        }
    }
    if (chart.userOptions.chartCursorType === 'sideband') {
        sideBandCursor(chart.hoverPoints[0], altIsPressed, chart.userOptions.isLockedFftSidebandLineCursor, currentSpeed, customCursorPointsCount);
    }
};
export var clearAdditionalFftPoints = function (chart) {
    chart.userOptions.harmonicPoints = [];
    chart.userOptions.secondHarmonicPoints = [];
    chart.userOptions.storedHarmonicPoints = [];
    chart.userOptions.sideBandPoint = null;
    removeAllByClassName('.sideBand-point');
    removeAllByClassName('.sideBand');
    removeAllByClassName('.sideBandLine');
    removeAllByClassName('.harmonic');
    removeAllByClassName('.harmonic-2');
};
export var sideBandCursor = function (point, altIsPressed, isLockedFftSidebandLineCursor, currentSpeed, customCursorPointsCount) {
    var _a;
    if (!((_a = point === null || point === void 0 ? void 0 : point.series) === null || _a === void 0 ? void 0 : _a.chart)) {
        return;
    }
    var chart = point.series.chart, chartIdentifier = chart.userOptions.chartIdentifier, currentSpeedHz = FrequencyConverter.fromCpm(currentSpeed, currentSpeed).toHz().value, sideBandPoint = chart.userOptions.sideBandPoint;
    if (!sideBandPoint) {
        return;
    }
    if (!isFftTwfChart(chartIdentifier)) {
        return;
    }
    if (isLockedFftSidebandLineCursor) {
        return;
    }
    removeAllByClassName('.sideBand-point', ".chart-item-".concat(chartIdentifier));
    removeAllByClassName('.sideBand', ".chart-item-".concat(chartIdentifier));
    removeAllByClassName('.sideBandLine', ".chart-item-".concat(chartIdentifier));
    var currentPoint = point, xData = sideBandPoint.series.xData, sideBandIndex = xData.indexOf(sideBandPoint.x), renderer = chart.renderer, isSidebandByRmp = altIsPressed && isTwfChart(chartIdentifier) && currentSpeedHz > 0;
    var left, top;
    var indexByRpm = getNearestPointIndex(point.series, 1 / currentSpeedHz + sideBandPoint.x);
    var step = Math.abs((isSidebandByRmp ? indexByRpm : xData.indexOf(currentPoint.x)) - xData.indexOf(sideBandPoint.x));
    var highlightedIndexes = _range(sideBandIndex - step * customCursorPointsCount, sideBandIndex + step * (customCursorPointsCount + 1), step);
    highlightedIndexes.map(function (index, indexVal) {
        if (point.series.data[index]) {
            left = chart.xAxis[0].toPixels(point.series.data[index].x) - 4;
            top = chart.yAxis[0].toPixels(point.series.data[index].y) - 4;
            renderer
                .rect(left, top, 8, 8, 1)
                .attr({
                fill: 'rgba(255, 255, 255, 0.1)',
                stroke: 'black',
                'stroke-width': 1,
                zIndex: 7,
                'point-id': index,
            })
                .addClass('sideBand')
                .add();
            renderer
                .rect(left + 4, (renderer.height - renderer.plotBox.height) / 2, 0.2, renderer.plotBox.height, 1)
                .attr({
                fill: 'white',
                stroke: 'black',
                'stroke-width': 0.2,
                zIndex: 7,
                'point-id': "line-".concat(index),
            })
                .addClass('sideBandLine')
                .add();
            if (indexVal === customCursorPointsCount) {
                renderer
                    .rect(left, top, 8, 8, 1)
                    .attr({
                    fill: 'green',
                    stroke: 'green',
                    'stroke-width': 1,
                    zIndex: 8,
                })
                    .addClass('sideBand-point')
                    .add();
            }
        }
    });
};
export var harmonicCursor = function (points, customCursorPointsCount, redraw, chart, harmonics) {
    if (redraw === void 0) { redraw = false; }
    if (chart === void 0) { chart = null; }
    if (harmonics === void 0) { harmonics = []; }
    if (_size(points) === 0 && !redraw) {
        return;
    }
    chart = chart || points[0].series.chart;
    var chartIdentifier = chart.userOptions.chartIdentifier, isLockedFftCursor = chart.userOptions.isLockedFftCursor;
    if ((!isFftTwfChart(chartIdentifier) || isLockedFftCursor) && !redraw) {
        return;
    }
    removeAllByClassName('.sideBand-point');
    removeAllByClassName('.sideBand');
    removeAllByClassName('.sideBandLine');
    removeAllByClassName('.harmonic', '.modal-fullscreen');
    removeAllByClassName('.harmonic', ".chart-item-".concat(chartIdentifier));
    drawHarmonicShapes(chart, points, customCursorPointsCount, 'red', 'harmonic', harmonics);
};
export var secondHarmonicCursor = function (points, customCursorPointsCount, redraw, chart) {
    if (redraw === void 0) { redraw = false; }
    if (chart === void 0) { chart = null; }
    if (_size(points) === 0) {
        return;
    }
    chart = chart || points[0].series.chart;
    var chartIdentifier = chart.userOptions.chartIdentifier, isLockedWithAltCursor = chart.userOptions.isLockedWithAltCursor;
    if ((!isFftTwfChart(chartIdentifier) || isLockedWithAltCursor) && !redraw) {
        return;
    }
    removeAllByClassName('.sideBand-point');
    removeAllByClassName('.sideBand');
    removeAllByClassName('.sideBandLine');
    removeAllByClassName('.harmonic-2', '.modal-fullscreen');
    removeAllByClassName('.harmonic-2', ".chart-item-".concat(chartIdentifier));
    drawHarmonicShapes(chart, points, customCursorPointsCount, 'blue', 'harmonic-2');
};
var drawHarmonicShapes = function (chart, points, customCursorPointsCount, color, className, harmonics) {
    var _a, _b;
    if (color === void 0) { color = 'red'; }
    if (className === void 0) { className = 'harmonic'; }
    if (harmonics === void 0) { harmonics = []; }
    var renderer = chart.renderer;
    var harmonicPoints = harmonics && harmonics.length > 0
        ? harmonics
        : getHarmonicPoints(((_a = chart.xAxis[0]) === null || _a === void 0 ? void 0 : _a.max) || ((_b = chart.xAxis[0]) === null || _b === void 0 ? void 0 : _b.dataMax), points, customCursorPointsCount);
    chart.userOptions.storedHarmonicPoints = harmonicPoints;
    harmonicPoints.forEach(function (harmonicPoint) {
        var left = chart.xAxis[0].toPixels(harmonicPoint.x) - 4;
        var top = chart.yAxis[0].toPixels(harmonicPoint.y) - 4;
        if (left > 80) {
            renderer
                .rect(left, top, 8, 8, 1)
                .attr({
                stroke: color,
                'stroke-width': 1,
                'point-id': harmonicPoint.highest_key_y,
                zIndex: 5,
            })
                .addClass(className)
                .add();
        }
    });
};
export var getHarmonicPoints = function (dataMax, points, customCursorPointsCount) {
    var result = [];
    for (var _i = 0, points_1 = points; _i < points_1.length; _i++) {
        var point = points_1[_i];
        if (point.index < 2) {
            continue;
        }
        var seria = point.series;
        if (seria && seria.visible && seria.userOptions.enableMouseTracking) {
            var count = Math.ceil(dataMax / point.x);
            count =
                customCursorPointsCount > 0 && count > customCursorPointsCount ? customCursorPointsCount + 1 : count;
            var highest_key_y = point.index, x = point.x, y = point.y;
            for (var i = 1; i < count; i++) {
                if (i !== 1) {
                    var value = point.x * i, closest_key = getNearestPointIndex(seria, value);
                    var highest_y = null;
                    for (var k = closest_key - 2; k < closest_key + 3; k++) {
                        if (seria.points[k]) {
                            if (highest_y === null || seria.points[k].y > highest_y) {
                                highest_y = seria.points[k].y;
                                highest_key_y = k;
                            }
                        }
                    }
                    x = seria.points[highest_key_y].x;
                    y = seria.points[highest_key_y].y;
                }
                result.push({ x: x, y: y, highest_key_y: highest_key_y });
            }
        }
    }
    return result;
};
export var searchPeaks = function (points) {
    var peaksArray = [];
    if (points.length > 2) {
        for (var i = 1; i < points.length; i++) {
            if (points[i] &&
                points[i - 1] &&
                points[i + 1] &&
                points[i][1] > points[i - 1][1] &&
                points[i][1] > points[i + 1][1] &&
                points[i][0] >= 5) {
                peaksArray.push(points[i]);
            }
        }
    }
    return peaksArray;
};
export var getHarmonicsByPeaks = function (peakFreqs) {
    var HZ_WINDOW = 2;
    var MIN_HARMONICS_FREQ = 5;
    var maxPeakFreq = Math.max.apply(Math, peakFreqs);
    var harmonicsLists = [];
    var usedFreqs = [];
    var _loop_1 = function (freq) {
        if (usedFreqs.includes(freq) || freq < MIN_HARMONICS_FREQ)
            return "continue";
        var window = (HZ_WINDOW * freq) / Math.sqrt(200 + Math.pow(freq, 2));
        var maxHarmonics = Math.floor(maxPeakFreq / freq);
        var harmWindows = Array.from({ length: maxHarmonics }, function (_, j) {
            var harmonic = j + 1;
            return [harmonic, freq * harmonic - window, freq * harmonic + window];
        });
        var harmonics = harmWindows.flatMap(function (_a) {
            var j = _a[0], min = _a[1], max = _a[2];
            return peakFreqs.filter(function (f) { return min <= f && f <= max; }).map(function (f) { return [j, f]; });
        });
        harmonics.forEach(function (_a) {
            var f = _a[1];
            if (!usedFreqs.includes(f)) {
                usedFreqs.push(f);
            }
        });
        if (harmonics.length > 1) {
            harmonicsLists.push(harmonics);
        }
    };
    for (var _i = 0, peakFreqs_1 = peakFreqs; _i < peakFreqs_1.length; _i++) {
        var freq = peakFreqs_1[_i];
        _loop_1(freq);
    }
    return harmonicsLists;
};
export var moveCursorByKeys = function (event) {
    var _a, _b, _c;
    if (event.keyCode === 39 || event.keyCode === 37) {
        var chartItem_1 = {};
        Highcharts.charts.forEach(function (chart) {
            if (!chart)
                return;
            if (!chart.userOptions)
                return;
            if (!document.getElementsByClassName('chart-item-' + chart.userOptions.chartIdentifier)[0])
                return;
            if (document
                .getElementsByClassName('chart-item-' + chart.userOptions.chartIdentifier)[0]
                .matches(':hover'))
                chartItem_1 = chart;
        });
        if (chartItem_1.hoverPoint) {
            event.preventDefault();
            var currentIndex_1 = chartItem_1.hoverPoint.index, currentPoint = chartItem_1.hoverPoint.series.points.filter(function (point) { return +point.index === +currentIndex_1; }), selectedPointIndex = currentPoint.length
                ? chartItem_1.hoverPoint.series.points.indexOf(currentPoint[0])
                : null;
            var hoverPointIndex = selectedPointIndex;
            var data = (_b = (_a = chartItem_1 === null || chartItem_1 === void 0 ? void 0 : chartItem_1.userOptions) === null || _a === void 0 ? void 0 : _a.series[0]) === null || _b === void 0 ? void 0 : _b.data;
            var peaks = ((_c = chartItem_1 === null || chartItem_1 === void 0 ? void 0 : chartItem_1.userOptions) === null || _c === void 0 ? void 0 : _c.peaks) || searchPeaks(data);
            var peakIndex = 0;
            if (event.keyCode === 37) {
                //arr
                var pointsMaxKey = chartItem_1.hoverPoint.series.points.length - 1; // ow left
                if (event.shiftKey) {
                    for (var i = 0; i < peaks.length; i++) {
                        if (peaks[i][0] >= chartItem_1.hoverPoint.x) {
                            peakIndex = i - 1;
                            break;
                        }
                    }
                    hoverPointIndex = getNearestPointIndex(chartItem_1 === null || chartItem_1 === void 0 ? void 0 : chartItem_1.series[0], peaks[peakIndex][0]);
                }
                else {
                    hoverPointIndex = selectedPointIndex < 1 ? 0 : selectedPointIndex - 1;
                }
                if (hoverPointIndex >= pointsMaxKey) {
                    return;
                }
                if (chartItem_1.hoverPoint.series.points[hoverPointIndex]) {
                    chartItem_1.hoverPoint.series.points[hoverPointIndex].onMouseOver(event);
                }
            }
            else if (event.keyCode === 39) {
                //arrow right
                var pointsMaxKey = chartItem_1.hoverPoint.series.points.length - 1;
                if (event.shiftKey) {
                    for (var i = 0; i < peaks.length; i++) {
                        if (peaks[i][0] >= chartItem_1.hoverPoint.x) {
                            peakIndex = i + 1;
                            break;
                        }
                    }
                    hoverPointIndex = getNearestPointIndex(chartItem_1 === null || chartItem_1 === void 0 ? void 0 : chartItem_1.series[0], peaks[peakIndex][0]);
                }
                else {
                    hoverPointIndex = selectedPointIndex > pointsMaxKey - 1 ? pointsMaxKey : selectedPointIndex + 1;
                }
                if (hoverPointIndex >= pointsMaxKey) {
                    return;
                }
                if (chartItem_1.hoverPoint.series.points[hoverPointIndex]) {
                    chartItem_1.hoverPoint.series.points[hoverPointIndex].onMouseOver(event);
                }
            }
            chartItem_1.tooltip.refresh(chartItem_1.hoverPoints);
        }
    }
};
export var setFFDetection = function (event, FFDetectionPoints, dispatch, chartRef) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (event.keyCode === 13) {
        if (((_c = (_b = (_a = chartRef === null || chartRef === void 0 ? void 0 : chartRef.current) === null || _a === void 0 ? void 0 : _a.chart) === null || _b === void 0 ? void 0 : _b.userOptions) === null || _c === void 0 ? void 0 : _c.chartCursorType) !== 'FFDetection') {
            return;
        }
        if (!((_e = (_d = chartRef === null || chartRef === void 0 ? void 0 : chartRef.current) === null || _d === void 0 ? void 0 : _d.chart) === null || _e === void 0 ? void 0 : _e.userOptions.FFDetectionPoints)) {
            chartRef.current.chart.userOptions.FFDetectionPoints = [];
        }
        return dispatch({
            type: 'setState',
            state: {
                FFDetectionPoints: __spreadArray(__spreadArray([], FFDetectionPoints, true), [(_g = (_f = chartRef === null || chartRef === void 0 ? void 0 : chartRef.current) === null || _f === void 0 ? void 0 : _f.chart) === null || _g === void 0 ? void 0 : _g.hoverPoint.x], false),
            },
        });
    }
};
export var setHarmonicsByPeaks = function (event) {
    var _a, _b, _c, _d, _e, _f;
    if (event.code === 'KeyX') {
        var chartItem_2 = {};
        Highcharts.charts.forEach(function (chart) {
            if (!chart)
                return;
            if (!chart.userOptions)
                return;
            if (!document.getElementsByClassName('chart-item-' + chart.userOptions.chartIdentifier)[0])
                return;
            if (document
                .getElementsByClassName('chart-item-' + chart.userOptions.chartIdentifier)[0]
                .matches(':hover'))
                chartItem_2 = chart;
        });
        if (((_a = chartItem_2 === null || chartItem_2 === void 0 ? void 0 : chartItem_2.userOptions) === null || _a === void 0 ? void 0 : _a.chartCursorType) !== 'harmonic') {
            return;
        }
        var data = (_c = (_b = chartItem_2 === null || chartItem_2 === void 0 ? void 0 : chartItem_2.userOptions) === null || _b === void 0 ? void 0 : _b.series[0]) === null || _c === void 0 ? void 0 : _c.data;
        var peaks = ((_d = chartItem_2 === null || chartItem_2 === void 0 ? void 0 : chartItem_2.userOptions) === null || _d === void 0 ? void 0 : _d.peaks) || searchPeaks(data);
        if (peaks.length === 0) {
            chartItem_2.userOptions.peakIndex = 0;
            clearAdditionalFftPoints(chartItem_2);
            return;
        }
        chartItem_2.userOptions.storedHarmonicByPeaks =
            chartItem_2.userOptions.storedHarmonicByPeaks || getHarmonicsByPeaks(peaks.map(function (peak) { return peak[0]; }));
        var peakIndex = chartItem_2.userOptions.peakIndex || 0;
        var harmonics = (chartItem_2.userOptions.storedHarmonicByPeaks[peakIndex] || []).map(function (storedHarmonicByPeaks) {
            var closestKey = getNearestPointIndex(chartItem_2 === null || chartItem_2 === void 0 ? void 0 : chartItem_2.series[0], storedHarmonicByPeaks[1]), point = chartItem_2 === null || chartItem_2 === void 0 ? void 0 : chartItem_2.series[0].points[closestKey];
            return { x: point.x, y: point.y, highest_key_y: point.index };
        });
        if (harmonics.length === 0) {
            chartItem_2.userOptions.peakIndex = 0;
            clearAdditionalFftPoints(chartItem_2);
            return;
        }
        chartItem_2.userOptions.isLockedFftCursor = false;
        harmonicCursor((_e = chartItem_2 === null || chartItem_2 === void 0 ? void 0 : chartItem_2.series[0]) === null || _e === void 0 ? void 0 : _e.points, ((_f = chartItem_2 === null || chartItem_2 === void 0 ? void 0 : chartItem_2.userOptions) === null || _f === void 0 ? void 0 : _f.customCursorPointsCount) || 10, false, chartItem_2, harmonics);
        chartItem_2.userOptions.isLockedFftCursor = true;
        chartItem_2.userOptions.peakIndex = peakIndex + 1;
    }
};
