var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get as _get } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { calculateSize } from '../../../components/charts/chartsWrapper/features/shared/size';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useFftTwfSizeChartsEffect = function (state, dispatch, loader) {
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options, isVisibleFullscreenChart = state.isVisibleFullscreenChart, isCircleTwfType = state.isCircleTwfType, isFFDetection = state.isFFDetection;
    var chartView = ChartsStore(function (state) { return ({
        chartView: state.chartView,
    }); }, shallow).chartView;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint, sensor = _get(installationPoint, 'sensor') || {};
    useEffect(function () {
        if (!mainSeriesLoaded || loader) {
            return;
        }
        var size = calculateSize(chartView);
        if (isVisibleFullscreenChart) {
            size = {
                height: window.innerHeight * (isFFDetection ? 0.5 : 0.8),
                width: window.innerWidth - 80,
            };
        }
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { chart: __assign(__assign({}, options.chart), size), yAxis: [
                        __assign(__assign({}, options.yAxis[0]), { title: {
                                rotation: -90,
                                x: isCircleTwfType
                                    ? isVisibleFullscreenChart
                                        ? -700
                                        : chartView === 'list'
                                            ? -200
                                            : -120
                                    : null,
                            }, labels: {
                                align: 'left',
                                x: isCircleTwfType
                                    ? isVisibleFullscreenChart
                                        ? -690
                                        : chartView === 'list'
                                            ? -190
                                            : -110
                                    : null,
                            } }),
                    ] }),
            },
        });
    }, [isVisibleFullscreenChart, chartView, sensor.is_hfdvue, loader, isFFDetection]);
};
