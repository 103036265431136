var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import DataTable from '../../../components/shared/Table/Table';
import { useOilReportsQuery } from '../../../services/actionItems/useOilReportsQuery';
import Loader from '../../Loader';
import { dataTableConfig } from './dataTableConfig';
export var OilReports = function (_a) {
    var equipmentId = _a.equipmentId;
    var _b = useOilReportsQuery({ equipmentId: equipmentId }), data = _b.data, areFetchingOilReports = _b.isFetching;
    return (_jsxs(_Fragment, { children: [areFetchingOilReports && (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })), data && (_jsx("div", { className: "mt-3", children: _jsx(DataTable, { config: __assign(__assign({}, dataTableConfig), { data: data || [] }) }) }))] }));
};
