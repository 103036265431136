var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onChangeState = function (state, dispatch, selectedStatus) {
    var _a;
    var selectedStatuses = __assign(__assign({}, state.config.filtersData.selectedStatuses), (_a = {}, _a[selectedStatus] = +!state.config.filtersData.selectedStatuses[selectedStatus], _a));
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { loader: true, filtersData: __assign(__assign({}, state.config.filtersData), { selectedStatuses: selectedStatuses }) }),
        },
    });
};
export var onChangeAiType = function (state, dispatch, selectedAiType) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { loader: true, filtersData: __assign(__assign({}, state.config.filtersData), { selectedAiType: selectedAiType || '' }) }),
        },
    });
};
export var onAdvancedSearch = function (state, dispatch, systemType, isActiveGlobalFilter) {
    var globalFilter = systemType === 'remastered' ? 'customer' : 'facility';
    if (!isActiveGlobalFilter) {
        toast.error("Please first select a ".concat(globalFilter, " to use advanced search"), {
            icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
        });
        return;
    }
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { filtersData: __assign(__assign({}, state.config.filtersData), { advancedSearch: !state.config.filtersData.advancedSearch }) }),
        },
    });
};
export var onResetQuickFilter = function (state, dispatch) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { loader: true, filtersData: __assign(__assign({}, state.config.filtersData), { increaseSeverity: false, selectedStatuses: {}, selectedAiType: null }) }),
        },
    });
};
export var changeFiltersData = function (state, dispatch, name, value) {
    var _a;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { filtersData: __assign(__assign({}, state.config.filtersData), (_a = {}, _a[name] = name === 'urgency' || name === 'readingType'
                    ? state.config.filtersData[name] === value
                        ? ''
                        : value
                    : value, _a)) }),
        },
    });
};
export var calcDaysForCharts = function (timeCreated) {
    if (!timeCreated) {
        return 'all';
    }
    var daysForCharts = Math.abs(moment(timeCreated).diff(moment(), 'days')) + 14;
    return daysForCharts <= 365 ? daysForCharts : 365;
};
export var groupRecommendations = function (recommendations, withOrdering) {
    if (withOrdering === void 0) { withOrdering = true; }
    var recommendationsGroupedByComponentType = [];
    recommendations.forEach(function (recommendation) {
        recommendationsGroupedByComponentType[recommendation.component_type_id || 0] =
            recommendationsGroupedByComponentType[recommendation.component_type_id || 0] || {};
        recommendationsGroupedByComponentType[recommendation.component_type_id || 0][recommendation.fault_id || 0] =
            recommendationsGroupedByComponentType[recommendation.component_type_id || 0][recommendation.fault_id || 0] || [];
        recommendationsGroupedByComponentType[recommendation.component_type_id || 0][recommendation.fault_id || 0].push(recommendation);
    });
    if (withOrdering) {
        Object.keys(recommendationsGroupedByComponentType).forEach(function (key) {
            recommendationsGroupedByComponentType[key] = Object.values(recommendationsGroupedByComponentType[key]).sort(function (a, b) { var _a, _b; return ((_a = b[0]) === null || _a === void 0 ? void 0 : _a.severity) - ((_b = a[0]) === null || _b === void 0 ? void 0 : _b.severity); });
        });
    }
    return recommendationsGroupedByComponentType;
};
