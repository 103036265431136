import { useEffect } from 'react';
import { clearAdditionalFftPoints, harmonicCursor, } from '../../../components/charts/chartsWrapper/features/shared/cursor';
import { onBlockedFftTwfMouseMove } from '../../../helper/chart';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useFftCursor = function (state, loader, chartRef) {
    var isCircleTwfType = state.isCircleTwfType, isFFDetection = state.isFFDetection;
    var chartCursorType = ChartsStore(function (state) { return state.chartCursorType; });
    var harmonicByPoint = ChartFeaturesStore(function (state) { return state.harmonicByPoint; });
    useEffect(function () {
        if (loader) {
            return;
        }
        if (isFFDetection) {
            chartRef.current.chart.userOptions.chartCursorType = 'FFDetection';
        }
        else {
            chartRef.current.chart.userOptions.FFDetectionPoints = [];
            chartRef.current.chart.userOptions.chartCursorType = chartCursorType;
        }
        if (harmonicByPoint.peak[0]) {
            return;
        }
        chartRef.current.chart.userOptions.isLockedCursor = false;
        chartRef.current.chart.userOptions.isLockedWithAltCursor = false;
        chartRef.current.chart.userOptions.isLockedFftCursor = false;
        chartRef.current.chart.userOptions.sideBandPoint = null;
        chartRef.current.chart.userOptions.storedHarmonicByPeaks = null;
        chartRef.current.chart.userOptions.peakIndex = null;
        clearAdditionalFftPoints(chartRef.current.chart);
        onBlockedFftTwfMouseMove(true, chartRef.current.chart);
    }, [chartCursorType, isCircleTwfType, isFFDetection]);
};
export var useCustomCursorPointsCount = function (loader, chartRef, state) {
    var customCursorPointsCount = state.customCursorPointsCount;
    useEffect(function () {
        if (loader) {
            return;
        }
        chartRef.current.chart.userOptions.customCursorPointsCount = customCursorPointsCount;
        chartRef.current.chart.userOptions.isLockedFftCursor = false;
        onBlockedFftTwfMouseMove(true, chartRef.current.chart);
    }, [customCursorPointsCount]);
};
export var useHarmoniCursorByPoint = function (loader, chartRef, chartIdentifier) {
    var _a = ChartFeaturesStore(function (state) { return ({
        harmonicByPoint: state.harmonicByPoint,
        setStatesChartFeaturesStore: state.setStates,
    }); }), harmonicByPoint = _a.harmonicByPoint, setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f;
        if (loader || !harmonicByPoint.peak[0] || chartIdentifier !== harmonicByPoint.chartIdentifier) {
            return;
        }
        chartRef.current.chart.userOptions.isLockedFftCursor = false;
        harmonicCursor((_c = (_b = (_a = chartRef.current) === null || _a === void 0 ? void 0 : _a.chart) === null || _b === void 0 ? void 0 : _b.series[0]) === null || _c === void 0 ? void 0 : _c.points.filter(function (point) { return point.x === harmonicByPoint.peak[0]; }), ((_f = (_e = (_d = chartRef.current) === null || _d === void 0 ? void 0 : _d.chart) === null || _e === void 0 ? void 0 : _e.userOptions) === null || _f === void 0 ? void 0 : _f.customCursorPointsCount) || 10, false, null, harmonicByPoint.harmonics);
        chartRef.current.chart.userOptions.isLockedFftCursor = true;
        setStatesChartFeaturesStore({
            harmonicByPoint: {
                peak: [],
                harmonics: [],
                chartIdentifier: null,
            },
        });
    }, [harmonicByPoint]);
};
