import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { clearAdditionalFftPoints } from '../../../components/charts/chartsWrapper/features/shared/cursor';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useResetCursorsOnAllCharts = function (state, chartIdentifier, loader, fftTimestampDates, isChangedCalendar, fftSelectedDate, chartRef) {
    var isVisibleFullscreenChart = state.isVisibleFullscreenChart, isFFDetection = state.isFFDetection;
    var isClearFftTwfCursor = ChartsStore(function (state) { return state.isClearFftTwfCursor; });
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    useEffect(function () {
        if (loader) {
            return;
        }
        clearAdditionalFftPoints(chartRef.current.chart);
    }, [isClearFftTwfCursor]);
    useEffect(function () {
        if (loader) {
            return;
        }
        chartRef.current.chart.userOptions.isLockedFftCursor = false;
        chartRef.current.chart.userOptions.isLockedWithAltFftCursor = false;
        clearAdditionalFftPoints(chartRef.current.chart);
    }, [installationPoint.settings, fftSelectedDate, isVisibleFullscreenChart, isFFDetection]);
    useEffect(function () {
        if (loader) {
            return;
        }
        if (isChangedCalendar) {
            chartRef.current.chart.userOptions.isLockedFftCursor = false;
            chartRef.current.chart.userOptions.isLockedWithAltFftCursor = false;
            clearAdditionalFftPoints(chartRef.current.chart);
        }
    }, [fftTimestampDates[chartIdentifier]]);
};
