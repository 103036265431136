import { jsx as _jsx } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
export var FFDetection = function (_a) {
    var dispatch = _a.dispatch, chartRef = _a.chartRef, isFFDetection = _a.isFFDetection, width = _a.width, isDisabled = _a.isDisabled;
    var onHandler = function () {
        if (isFFDetection) {
            dispatch({
                type: 'setState',
                state: {
                    isFFDetection: false,
                    FFDetectionPoints: [],
                },
            });
        }
        else {
            dispatch({
                type: 'setState',
                state: {
                    isCircleTwfType: false,
                    isVisibleFullscreenChart: true,
                    isFFDetection: true,
                    fftFullscreenUserOptions: chartRef ? _cloneDeep(chartRef.current.chart.userOptions) : {},
                },
            });
        }
    };
    return (_jsx("div", { className: "chart-btn", children: _jsx(Button, { tag: "div", style: { width: width }, className: "chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: isFFDetection ? 'primary' : 'secondary', size: "sm", disabled: isDisabled, onClick: onHandler, children: "FF Label" }) }));
};
