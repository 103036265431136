import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, has as _has, uniq as _uniq } from 'lodash';
import React, { useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledTooltip, } from 'reactstrap';
import { ACTION_LIST, READING_TYPES, READING_TYPE_ACCELERATION_X, READING_TYPE_PK_PK_X, READING_TYPE_TEMPERATURE, READING_TYPE_VELOCITY_X, } from '../../../constants';
import * as tableColumnStorage from '../../../storage/tableColumn';
import { DateTimeLabel } from '../../../widgets/common';
import Filters from '../../components/actionItem/Filter';
import { highlightRow } from '../../helper/table';
import User from '../../helper/user';
export var config = function (onViewClick, isActive, statusColumn, urgencyColumn, closedByColumn, installationPointId, statusNames, isHardwarePage, tableName) {
    var visibleState = tableColumnStorage.get(tableName || '');
    var selectedStatuses = {};
    var query = new URLSearchParams(window.location.search);
    var state = query.get('state');
    if (state !== null) {
        selectedStatuses[state] = 1;
    }
    return {
        columns: [
            {
                name: 'time_created',
                visible: _has(visibleState, 'time_created') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.time_created : true,
                title: 'Date created',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { timeBreak: true, id: "ai_list_time_created_".concat(row.id), dateTime: row.time_created }));
                },
            },
            {
                name: 'created_by',
                visible: _has(visibleState, 'created_by') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.created_by : true,
                title: 'Added by',
                sortable: true,
            },
            closedByColumn,
            {
                name: 'updated_at',
                visible: _has(visibleState, 'updated_at') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.updated_at : true,
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { timeBreak: true, id: "ai_list_updated_at_".concat(row.id), dateTime: row.updated_at }));
                },
            },
            {
                name: 'sla',
                visible: _has(visibleState, 'sla')
                    ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sla
                    : isActive &&
                        tableName !== 'amazonActionListHardwareTable' &&
                        tableName !== 'actionListHardwareTable',
                permissions: isActive,
                title: 'SLA',
                sortable: true,
                component: function (row) {
                    return row.sla || parseInt(row.sla) === 0 ? row.sla : '---';
                },
            },
            {
                name: 'priority',
                visible: _has(visibleState, 'priority')
                    ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.priority
                    : isActive &&
                        tableName !== 'amazonActionListHardwareTable' &&
                        tableName !== 'actionListHardwareTable',
                permissions: isActive,
                title: 'Priority',
                sortable: true,
                component: function (row) {
                    return row.priority === null ? '---' : row.priority;
                },
            },
            {
                name: 'count_declined_reports',
                visible: _has(visibleState, 'count_declined_reports')
                    ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.count_declined_reports
                    : isActive &&
                        User.can('viewDeclinedCR') &&
                        tableName !== 'amazonActionListHardwareTable' &&
                        tableName !== 'actionListHardwareTable',
                permissions: isActive && User.can('viewDeclinedCR'),
                title: 'Declined СR',
                sortable: true,
                component: function (row) {
                    return User.can('viewDeclinedCR') && row.count_declined_reports
                        ? +row.count_declined_reports
                        : '---';
                },
            },
            {
                name: 'replacement_type',
                visible: isHardwarePage && (_has(visibleState, 'replacement_type') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.replacement_type : true),
                permissions: isHardwarePage,
                title: 'Type',
                sortable: false,
                component: function (row) {
                    return row.replacement_type || 'Other';
                },
            },
            {
                name: 'days_ai_has_been_open',
                visible: _has(visibleState, 'days_ai_has_been_open') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.days_ai_has_been_open : true,
                title: 'AI lifecycle',
                sortable: true,
            },
            {
                name: 'readingTypes',
                visible: !isHardwarePage && isActive && _has(visibleState, 'readingTypes')
                    ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.readingTypes
                    : true,
                permissions: !isHardwarePage && isActive,
                titleComponent: function (callbacks, filtersData) {
                    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
                    return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: filtersData.readingType
                                    ? _get(READING_TYPES, [filtersData.readingType, 'title'], '')
                                    : 'Reading Types' }), _jsxs(DropdownMenu, { className: "dropdown-menu", style: { marginLeft: '-30px' }, children: [_jsx(DropdownItem, { onClick: function () { return callbacks.changeFiltersData('readingType', READING_TYPE_TEMPERATURE); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_TEMPERATURE }), "Temperature"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.changeFiltersData('readingType', READING_TYPE_ACCELERATION_X); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_ACCELERATION_X }), "Acceleration"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.changeFiltersData('readingType', READING_TYPE_VELOCITY_X); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_VELOCITY_X }), "Velocity"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.changeFiltersData('readingType', READING_TYPE_PK_PK_X); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_PK_PK_X }), "PK-PK"] }) })] })] }));
                },
                sortable: false,
            },
            urgencyColumn,
            statusColumn,
            {
                name: 'actions',
                visible: true,
                sortable: false,
                component: function (row, callbacks, filters) {
                    var gatheringDataUsers = _uniq((_get(row, 'gathering_data_users') || '').split(',')).filter(function (item) { return item.length > 0; });
                    return (_jsxs(Button, { color: "primary", className: "me-2 position-relative", size: "sm", onClick: function (ev) {
                            onViewClick(row.id, filters.advancedSearch ? filters.search : '');
                            highlightRow(ev);
                        }, children: ["View", row.type === ACTION_LIST.AI_TYPE_WW ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "sub-badge sub-badge-letter", id: "w_".concat(row.id), children: "W" }), _jsx(UncontrolledTooltip, { placement: "left", target: "w_".concat(row.id), children: "Action required on Waites equipment attached to this monitored point" })] })) : (_jsx(React.Fragment, { children: +row.is_replace_equipment === 1 ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "sub-badge sub-badge-letter", id: "r_".concat(row.id), children: "R" }), _jsx(UncontrolledTooltip, { placement: "left", target: "r_".concat(row.id), children: "This Action Item require replacing Waites equipment" })] })) : (_jsx(_Fragment, {})) })), (gatheringDataUsers.length > 0 || row.has_gathering_data) &&
                                row.type !== ACTION_LIST.AI_TYPE_WW &&
                                +row.is_replace_equipment === 0 ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "sub-badge sub-badge-letter", id: "gatherin_users_".concat(row.id), children: "A" }), gatheringDataUsers.length > 0 ? (_jsx(UncontrolledTooltip, { placement: "left", target: "gatherin_users_".concat(row.id), children: gatheringDataUsers.map(function (username) { return (_jsxs("div", { children: [username, " Analyst left a note to collect data on this AI"] }, "gatherin_users_".concat(row.id, "_").concat(username))); }) })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            if (installationPointId) {
                return _jsx(_Fragment, {});
            }
            return (_jsx(Filters, { showWwAiFilter: tableName === 'amazonActionListArchiveTable' || tableName === 'actionListArchiveTable', statusNames: statusNames, statesCounts: filtersData.statesCounts, isActiveAisPage: isActive, filtersData: filtersData, onAdvancedSearch: callbacks.onAdvancedSearch, changeFiltersData: callbacks.changeFiltersData, onChangeAiType: callbacks.onChangeAiType, onResetQuickFilter: callbacks.onResetQuickFilter, onChangeState: callbacks.onChangeState, onSetIncreaseSeverity: callbacks.onSetIncreaseSeverity, isHardwarePage: isHardwarePage, onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange }));
        },
        filtersData: {
            dateRange: [],
            increaseSeverity: state === 'increaseSeverity',
            statesCounts: {},
            isActiveAisPage: isActive,
            selectedStatuses: selectedStatuses,
            advancedSearch: false,
            hide_hardware_ai: +(tableName !== 'amazonActionListHardwareTable' && tableName !== 'actionListHardwareTable'),
            search: '',
            urgency: '',
            hardwareActionItemsTypes: [],
            selectedAiType: null,
            readingType: null,
            watchingFilter: '',
        },
    };
};
