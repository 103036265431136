var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, has as _has } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import ActivityIndicator from '../../../../shared/components/AIPreAi/ActivityIndicator';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../../shared/config/actionItems/dataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { changeFiltersData, onAdvancedSearch, onChangeAiType, onChangeState, onResetQuickFilter, } from '../../../../shared/features/actionItems';
import { onChangeWatchingFilter } from '../../../../shared/features/preActionItem';
import { exportToCsv, onChangeDateRangeFilter, onViewActionItem } from '../../../../shared/features/shared';
import { reducer } from '../../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../../shared/hooks/useResetDataTableEffect';
import ExistingAiPreAiOnBedModal from '../../../../shared/modals/ExistingAiPreAiOnBed';
import { useExistingAiPreAiActions } from '../../../../shared/modals/ExistingAiPreAiOnBed/store/useExistingAiPreAiOnBedStore';
import '../../../../shared/styles/AIPreAi/actionItems.scss';
import * as tableColumnStorage from '../../../../storage/tableColumn';
import { Money } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import { ACTION_LIST, WO_STATUSES } from '../../../constants';
import StatusLabelAmazon from '../../AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../AIPreAi/UrgencyLabel';
var initialState = function (isActive, installationPointId, onViewActionItem, setIsVisible, setInstallationPointId, setEquipmentId, isHardwarePage, tableName) {
    var visibleState = tableColumnStorage.get(tableName || '');
    var closedByColumn = {
        name: 'closed_by',
        visible: _has(visibleState, 'closed_by') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.closed_by : !isActive,
        permissions: !isActive,
        title: 'Closed by',
        sortable: true,
    };
    var urgencyColumn = {
        name: 'urgency_column',
        titleComponent: function (callbacks, filtersData) {
            var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
            var labelsMapping = {
                '1': 'Standard',
                '2': 'High',
                '3': 'Critical',
            };
            var field = filtersData.urgency;
            return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: labelsMapping[field] || 'Urgency' }), _jsx(DropdownMenu, { className: "dropdown-menu", style: { marginLeft: '-30px' }, children: _jsx("div", { children: Object.values(labelsMapping).map(function (label, index) { return (_jsx(DropdownItem, { onClick: function () { return callbacks.changeFiltersData('urgency', Object.keys(labelsMapping)[index]); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.urgency === Object.keys(labelsMapping)[index] }), label] }) }, "urgency_title_filter_".concat(label))); }) }) })] }));
        },
        sortable: false,
        visible: _has(visibleState, 'urgency') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.urgency : true,
        component: function (row) {
            return (_jsx(UrgencyLabel, { urgency: +row.urgency, type: row.type, replacementHistory: row.replacementHistory }));
        },
    };
    var statusColumn = {
        name: 'status',
        visible: _has(visibleState, 'status') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.status : true,
        title: 'Status',
        sortable: true,
        component: function (row) {
            return (_jsx(StatusLabelAmazon, { isTroubleshootingHoldWaiting: row.is_troubleshooting_waiting ? +row.is_troubleshooting_waiting : 0, status: +row.state, commentsCount: +row.comments_count }));
        },
    };
    var config = baseConfig(onViewActionItem, isActive, statusColumn, urgencyColumn, closedByColumn, installationPointId, ACTION_LIST.STATUS_NAMES, isHardwarePage, tableName);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                },
                {
                    name: '',
                    titleComponent: function (callbacks, filtersData) {
                        var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
                        var labelsMapping = {
                            not_watched: 'Not viewed',
                            watching: 'Watching',
                            watched: 'Processed',
                        };
                        var field = filtersData.watchingFilter;
                        return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: labelsMapping[field] || 'All' }), _jsxs(DropdownMenu, { className: "dropdown-menu", children: [_jsx(DropdownItem, { onClick: function () { return callbacks.onChangeWatchingFilter('not_watched'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.watchingFilter === 'not_watched' }), "Not viewed"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.onChangeWatchingFilter('watching'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.watchingFilter === 'watching' }), "Watching"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.onChangeWatchingFilter('watched'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.watchingFilter === 'watched', onChange: function () { return callbacks.onChangeWatchingFilter('watched'); } }), "Processed"] }) })] })] }));
                    },
                    component: function (row) {
                        var _a;
                        return _jsx(ActivityIndicator, { className: "mt-2", analystLog: (_a = row === null || row === void 0 ? void 0 : row.actionListAnalystLog) !== null && _a !== void 0 ? _a : [] });
                    },
                    sortable: false,
                },
                {
                    name: 'facility_name',
                    visible: _has(visibleState, 'facility_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name : true,
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'zone',
                    title: 'Zone',
                    sortable: true,
                },
                {
                    name: 'area',
                    title: 'Area',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                    component: function (row) {
                        return (_jsx(_Fragment, { children: row.activeAisOnEquipmentCount ? (_jsx("div", { className: "fake-link", onClick: function () {
                                    var _a;
                                    setIsVisible(true);
                                    setInstallationPointId((_a = +(row === null || row === void 0 ? void 0 : row.installation_point_id)) !== null && _a !== void 0 ? _a : null);
                                }, children: row.equipment_info || row.equipment_name || '---' })) : (row.equipment_info || row.equipment_name || '---') }));
                    },
                },
                {
                    name: 'point_name',
                    visible: _has(visibleState, 'point_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.point_name : true,
                    title: 'Point name',
                    sortable: true,
                },
                {
                    name: 'sensor_id',
                    visible: _has(visibleState, 'sensor_id') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sensor_id : true,
                    title: tableName === 'amazonActionListActiveTable' ? 'Sensor ID' : 'Serial/Sensor ID',
                    sortable: true,
                    component: function (row) {
                        return (_jsxs("div", { className: "no-wrap", children: [row.serial ? "".concat(row.serial, "/") : '', _jsx(SensorId, { notShowEmptyHex: true, hexBreak: true, sensor_id: +row.sensor_id })] }));
                    },
                },
                {
                    name: 'cost_avoided',
                    visible: _has(visibleState, 'cost_avoided')
                        ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.cost_avoided
                        : !isActive && tableName !== 'amazonActionListByInstallationPointTable',
                    title: 'Cost avoided',
                    permissions: !isActive,
                    sortable: true,
                    component: function (row) {
                        return row.cost_avoided || row.cost_avoided === 0 ? (_jsx(Money, { amount: row.cost_avoided })) : (_jsx(_Fragment, { children: "---" }));
                    },
                },
                {
                    name: 'wo_number',
                    visible: _has(visibleState, 'wo_number') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.wo_number : true,
                    title: 'WO',
                    sortable: true,
                    component: function (row) {
                        return !row.wo_number ? _jsx(_Fragment, { children: "---" }) : +row.wo_number === 0 ? 'Do not create' : row.wo_number;
                    },
                },
                {
                    name: 'wo_status',
                    visible: _has(visibleState, 'wo_status') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.wo_status : true,
                    title: 'WO status',
                    sortable: true,
                    component: function (row) {
                        return row.wo_status ? WO_STATUSES[row.wo_status] : _jsx(_Fragment, { children: "---" });
                    },
                },
                {
                    name: 'lph_avoided',
                    visible: _has(visibleState, 'lph_avoided') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.lph_avoided : !isHardwarePage,
                    permissions: !isHardwarePage,
                    title: 'LPH',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId, fetchStatesCounts, disableAdvancedSearch) {
    if (fetchStatesCounts === void 0) { fetchStatesCounts = false; }
    if (disableAdvancedSearch === void 0) { disableAdvancedSearch = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var config, actionListResponse, updatedConfig, actionListFilterInfoResponse;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        return __generator(this, function (_m) {
            switch (_m.label) {
                case 0:
                    config = state.config;
                    return [4 /*yield*/, Endpoints.amazon.getActionList({
                            query: {
                                onlyActive: +isActive,
                                hide_hardware_ai: (_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.hide_hardware_ai,
                                only_hardware_ai: +isHardwarePage,
                                selected_statuses: (_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.selectedStatuses,
                                selected_ai_type: (_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.selectedAiType,
                                selected_facility: selectedFacility,
                                installation_point_id: installationPointId,
                                increase_severity: ((_d = config === null || config === void 0 ? void 0 : config.filtersData) === null || _d === void 0 ? void 0 : _d.increaseSeverity) ? 1 : 0,
                                pagination: config.pagination,
                                sort: config.sort,
                                watching_filter: (_e = config === null || config === void 0 ? void 0 : config.filtersData) === null || _e === void 0 ? void 0 : _e.watchingFilter,
                                search: !disableAdvancedSearch ? config.search : '',
                                advanced_search: ((_f = config === null || config === void 0 ? void 0 : config.filtersData) === null || _f === void 0 ? void 0 : _f.advancedSearch) && !disableAdvancedSearch ? 1 : 0,
                                urgency: (_g = config === null || config === void 0 ? void 0 : config.filtersData) === null || _g === void 0 ? void 0 : _g.urgency,
                                reading_type: (_h = config === null || config === void 0 ? void 0 : config.filtersData) === null || _h === void 0 ? void 0 : _h.readingType,
                                date_from: ((_j = config === null || config === void 0 ? void 0 : config.filtersData) === null || _j === void 0 ? void 0 : _j.dateRange[0])
                                    ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                    : '',
                                date_to: ((_k = config === null || config === void 0 ? void 0 : config.filtersData) === null || _k === void 0 ? void 0 : _k.dateRange[1])
                                    ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                    : '',
                            },
                        })];
                case 1:
                    actionListResponse = _m.sent();
                    updatedConfig = __assign(__assign({}, config), { data: (actionListResponse === null || actionListResponse === void 0 ? void 0 : actionListResponse.data) || [], loader: false, pagination: __assign(__assign({}, config.pagination), { total: (actionListResponse === null || actionListResponse === void 0 ? void 0 : actionListResponse.total) || 0 }), filtersData: __assign(__assign({}, config.filtersData), { isActiveAisPage: isActive, installationPointId: installationPointId, selectedFacility: selectedFacility, search: config.search }) });
                    if (!fetchStatesCounts) return [3 /*break*/, 3];
                    return [4 /*yield*/, Endpoints.amazon.getActionListFilterInfo({
                            query: {
                                selected_facility: selectedFacility,
                                onlyActive: isActive ? 1 : 0,
                                hide_hardware_ai: (_l = config === null || config === void 0 ? void 0 : config.filtersData) === null || _l === void 0 ? void 0 : _l.hide_hardware_ai,
                                only_hardware_ai: isHardwarePage ? 1 : 0,
                            },
                        })];
                case 2:
                    actionListFilterInfoResponse = _m.sent();
                    updatedConfig = __assign(__assign({}, updatedConfig), { filtersData: __assign(__assign({}, updatedConfig.filtersData), { statesCounts: {
                                total: (actionListResponse === null || actionListResponse === void 0 ? void 0 : actionListResponse.total) || 0,
                                states: actionListFilterInfoResponse.states_count,
                                wwMissCount: actionListFilterInfoResponse.ww_missed_ais_count,
                                declinedReportsCount: actionListFilterInfoResponse.with_declined_reports_count,
                                withPreCommentsCount: actionListFilterInfoResponse.with_pre_comments_count,
                                severityIncreaseCount: actionListFilterInfoResponse.severity_increase_count,
                                troubleshootAiCount: actionListFilterInfoResponse.troubleshoot_ais_count,
                                waitesNewAiCount: actionListFilterInfoResponse.waites_new_ais_count,
                                troubleshootWaitingAiCount: actionListFilterInfoResponse.troubleshoot_waiting_ais_count,
                                troubleshootQuestionAiCount: actionListFilterInfoResponse.troubleshoot_question_ais_count,
                                noResponseCount: actionListFilterInfoResponse.no_response_count,
                                customerCreatedAisCount: actionListFilterInfoResponse.customer_created_ais_count,
                            }, hardwareActionItemsTypes: actionListFilterInfoResponse.hardwareActionItemsTypes }) });
                    _m.label = 3;
                case 3:
                    if (disableAdvancedSearch) {
                        updatedConfig = __assign(__assign({}, updatedConfig), { search: '', filtersData: __assign(__assign({}, updatedConfig.filtersData), { advancedSearch: false, search: '' }) });
                    }
                    if (actionListResponse) {
                        dispatch({ type: 'setState', state: { config: updatedConfig } });
                    }
                    return [2 /*return*/];
            }
        });
    });
};
var BaseActionItemsPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c, _d = _a.title, title = _d === void 0 ? 'Action list' : _d, _e = _a.isActive, isActive = _e === void 0 ? true : _e, _f = _a.isHardwarePage, isHardwarePage = _f === void 0 ? false : _f, tableName = _a.tableName, installationPointId = _a.installationPointId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _g = useExistingAiPreAiActions(), setIsVisible = _g.setIsVisible, setInstallationPointId = _g.setInstallationPointId, setEquipmentId = _g.setEquipmentId;
    var _h = useReducer(reducer, initialState(isActive, installationPointId, function (actionItemId, advancedSearch) { return onViewActionItem(actionItemId, selectedSystemType, advancedSearch); }, setIsVisible, setInstallationPointId, setEquipmentId, isHardwarePage, tableName)), state = _h[0], dispatch = _h[1];
    var config = state.config;
    var searchPlaceholder = _get(state, 'advancedSearch') ? 'Advanced Search' : 'Search';
    useResetDataTableEffect([
        selectedFacility,
        config.filtersData.selectedStatuses,
        config.filtersData.selectedAiType,
        config.filtersData.dateRange,
        config.filtersData.urgency,
        config.filtersData.readingType,
    ], state, dispatch);
    useEffect(function () {
        if (Object.values(config.filtersData.selectedStatuses).length > 0 ||
            config.filtersData.selectedAiType !== null) {
            onResetQuickFilter(state, dispatch);
        }
    }, [selectedFacility]);
    useEffect(function () {
        var disableAdvancedSearch = !selectedFacility && config.filtersData.advancedSearch;
        fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId, true, disableAdvancedSearch);
    }, [refreshFlag, selectedFacility]);
    useEffect(function () {
        if ((Object.values(config.filtersData.selectedStatuses).length === 0 &&
            config.filtersData.selectedAiType === null) ||
            Object.values(config.filtersData.statesCounts).length === 0) {
            return;
        }
        fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
    }, [config.filtersData.selectedStatuses, config.filtersData.selectedAiType]);
    useEffect(function () {
        if (config.loader) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign({}, state.config), { loader: true }),
            },
        });
        fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
    }, [config.filtersData.hide_hardware_ai, config.filtersData.increaseSeverity]);
    useEffect(function () {
        if (!config.loader && config.filtersData.dateRange.length > 0) {
            fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
        }
    }, [config.filtersData.dateRange]);
    useEffect(function () {
        if (!config.loader) {
            fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
        }
    }, [config.filtersData.urgency, config.filtersData.readingType, config.filtersData.watchingFilter]);
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "response-table align-pagination-section change-message-badge", children: [_jsxs("h3", { className: "page-title", children: [title, config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                                            return exportToCsv(Endpoints.amazon.getActionListCsv, 'amazon-action-items', {
                                                query: {
                                                    onlyActive: +isActive,
                                                    hide_hardware_ai: (_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.hide_hardware_ai,
                                                    only_hardware_ai: +isHardwarePage,
                                                    selected_statuses: (_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.selectedStatuses,
                                                    selected_ai_type: (_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.selectedAiType,
                                                    selected_facility: selectedFacility,
                                                    installation_point_id: installationPointId,
                                                    increase_severity: +((_d = config === null || config === void 0 ? void 0 : config.filtersData) === null || _d === void 0 ? void 0 : _d.increaseSeverity),
                                                    urgency: (_e = config === null || config === void 0 ? void 0 : config.filtersData) === null || _e === void 0 ? void 0 : _e.urgency,
                                                    reading_type: (_f = config === null || config === void 0 ? void 0 : config.filtersData) === null || _f === void 0 ? void 0 : _f.readingType,
                                                    sort: config.sort,
                                                    search: config.search,
                                                    advanced_search: ((_g = config === null || config === void 0 ? void 0 : config.filtersData) === null || _g === void 0 ? void 0 : _g.advancedSearch) ? 1 : 0,
                                                    date_from: ((_h = config === null || config === void 0 ? void 0 : config.filtersData) === null || _h === void 0 ? void 0 : _h.dateRange[0])
                                                        ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                        : '',
                                                    date_to: ((_j = config === null || config === void 0 ? void 0 : config.filtersData) === null || _j === void 0 ? void 0 : _j.dateRange[1])
                                                        ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                        : '',
                                                },
                                                isBlob: true,
                                            });
                                        }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx(DataTable, { searchPlaceholder: searchPlaceholder, config: config, tableName: tableName, dispatchTable: dispatch, onPageChange: function (page) {
                                    return onPageChange(page, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
                                    });
                                }, onPageSizeChange: function (pageSize) {
                                    return onPageSizeChange(pageSize, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
                                    });
                                }, onSort: function (column_name, ev) {
                                    return onSort(column_name, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
                                    }, ev);
                                }, callbacks: {
                                    onChangeState: function (selectedStatus) {
                                        return onChangeState(state, dispatch, selectedStatus);
                                    },
                                    onResetQuickFilter: function () { return onResetQuickFilter(state, dispatch); },
                                    onAdvancedSearch: function () {
                                        return onAdvancedSearch(state, dispatch, selectedSystemType, !!selectedFacility);
                                    },
                                    onChangeAiType: function (selectedAiType) { return onChangeAiType(state, dispatch, selectedAiType); },
                                    changeFiltersData: function (name, value) {
                                        return changeFiltersData(state, dispatch, name, value);
                                    },
                                    onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                                    onChangeWatchingFilter: function (filter) { return onChangeWatchingFilter(state, dispatch, filter); },
                                }, onSearch: function (search) {
                                    if ((search.includes(')') || search.includes('(')) &&
                                        state.config.filtersData.advancedSearch) {
                                        return toast.error('There is an invalid character in the search field!', {
                                            icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                                        });
                                    }
                                    onSearch(search, state, dispatch, function () {
                                        return fetch(state, dispatch, selectedFacility, isActive, isHardwarePage, installationPointId);
                                    });
                                } })] }) }) }), _jsx(ExistingAiPreAiOnBedModal, {})] }));
};
export default BaseActionItemsPage;
