import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
var useQuery = useUntypedQuery;
var oilReportsQueryFn = function (_a) {
    var _b = _a.queryKey, _ = _b[0], equipmentId = _b[1];
    return Api.get("all-analytics/machine-info/oil-report/".concat(equipmentId));
};
export var useOilReportsQuery = function (_a) {
    var equipmentId = _a.equipmentId;
    return useQuery({
        queryKey: ['oilReports', equipmentId],
        queryFn: oilReportsQueryFn,
        placeholderData: { data: [] },
        select: function (_a) {
            var data = _a.data;
            return data;
        },
    });
};
