var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import { getFFDetectionConfig } from '../../../components/charts/chartsWrapper/components/shared/chartToolsButtons/FFDetection/features';
import { setFFDetection } from '../../../components/charts/chartsWrapper/features/shared/cursor';
export var useFFDetection = function (state, dispatch, chartRef) {
    var FFDetectionPoints = state.FFDetectionPoints, loader = state.loader, options = state.options;
    useEffect(function () {
        var _a;
        if (loader || !((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        var handler = function (event) { return setFFDetection(event, FFDetectionPoints, dispatch, chartRef); };
        updateFFDetection(chartRef, FFDetectionPoints);
        chartRef.current.chart.userOptions.FFDetectionPoints = FFDetectionPoints;
        document.removeEventListener('keydown', handler);
        document.addEventListener('keydown', handler);
        return function () {
            document.removeEventListener('keydown', handler);
        };
    }, [FFDetectionPoints]);
};
var updateFFDetection = function (chartRef, FFDetectionPoints) {
    var otherPlotLines = (chartRef.current.chart.xAxis[0].plotLinesAndBands || [])
        .filter(function (plotLine) { return plotLine.options.type !== 'FFDetection'; })
        .map(function (plotLine) { return plotLine.options; });
    chartRef.current.chart.xAxis[0].update({
        plotLines: __spreadArray(__spreadArray([], otherPlotLines, true), getFFDetectionConfig(FFDetectionPoints), true),
    });
};
