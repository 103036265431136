import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTimeLabel, DateTimeLabelWithUTC } from '../../../../widgets/common';
import { getIcon, isImage } from '../../../helper/file';
export var dataTableConfig = {
    columns: [
        {
            name: 'created_by',
            title: 'Added By',
            sortable: false,
        },
        {
            name: 'created_at',
            title: 'Date Create',
            sortable: false,
            component: function (_a) {
                var created_at = _a.created_at;
                return _jsx(DateTimeLabel, { dateTime: created_at });
            },
        },
        {
            name: 'updated_at',
            title: 'Date Update',
            sortable: false,
            component: function (_a) {
                var updated_at = _a.updated_at;
                return _jsx(DateTimeLabel, { dateTime: updated_at });
            },
        },
    ],
    data: [],
    loader: false,
    hideTotalLabel: true,
    pagination: {
        disabled: true,
    },
    search: '',
    disabledSearch: true,
    getIsShowAdditionContent: function () { return true; },
    additionContent: function (_a) {
        var text = _a.text, created_by = _a.created_by, created_at = _a.created_at, _b = _a.files, files = _b === void 0 ? [] : _b;
        return (_jsxs("div", { className: "table-additional-content", children: ["SSD Note", _jsx("br", {}), _jsx("b", { children: "Description: " }), _jsx("span", { dangerouslySetInnerHTML: { __html: text } }), _jsx("br", {}), _jsx("b", { children: "Added by: " }), created_by, _jsx("br", {}), _jsx("b", { children: "Date create: " }), _jsx(DateTimeLabelWithUTC, { dateTime: created_at }), _jsx("br", {}), !!files.length && (_jsx(_Fragment, { children: files.map(function (file) { return (_jsx("div", { className: "d-inline-block missed-attachments card", title: file.name, children: _jsx("a", { href: file.url, target: "_blank", rel: "noreferrer", children: isImage(file) ? (_jsx("img", { src: file.url, alt: file.url })) : (_jsx("span", { className: "file-icon", children: getIcon(file) })) }) }, file.id)); }) }))] }));
    },
};
