var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { Fragment, memo, useCallback, useContext, useEffect, } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { shallow } from 'zustand/shallow';
import { SYSTEM_TYPES_DATA } from '../../../../constants';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { abortOverlayRequests, onScroll, updateUrl } from '../../../features/charts/leftEquipmentList';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { LeftEquipmentListStore } from '../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import '../../../styles/charts/leftEquipmentList/leftEquipmentList.scss';
import Loader from '../../Loader';
import EquipmentRow from './EquipmentRow';
var LeftEquipmentList = function (_a) {
    var _b = _a.deps, deps = _b === void 0 ? [] : _b, fetchEquipmentsCallback = _a.fetchEquipmentsCallback, fetchEquipmentsCallbackBySearch = _a.fetchEquipmentsCallbackBySearch, onOverlayCheck = _a.onOverlayCheck, equipmentTitle = _a.equipmentTitle, equipmentName = _a.equipmentName, prevInstallationPoint = _a.prevInstallationPoint, setFilter = _a.setFilter;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var trackEvent = useMatomo().trackEvent;
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var _c = InstallationPointStore(function (state) { return ({
        setStatesInstallationPointStore: state.setStates,
        installationPoint: state.installationPoint,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
    }); }, shallow), setStatesInstallationPointStore = _c.setStatesInstallationPointStore, installationPoint = _c.installationPoint, overlayInstallationPoints = _c.overlayInstallationPoints;
    var _d = LeftEquipmentListStore(function (state) { return ({
        filter: state.filter,
        equipments: state.equipments,
        isLoading: state.isLoading,
        offset: state.offset,
        limit: state.limit,
        lastReceivedEquipmentsCount: state.lastReceivedEquipmentsCount,
        onInfiniteScroll: state.onInfiniteScroll,
        resetOffset: state.resetOffset,
    }); }, shallow), filter = _d.filter, equipments = _d.equipments, isLoading = _d.isLoading, offset = _d.offset, limit = _d.limit, lastReceivedEquipmentsCount = _d.lastReceivedEquipmentsCount, onInfiniteScroll = _d.onInfiniteScroll, resetOffset = _d.resetOffset;
    useEffect(function () {
        fetchEquipmentsCallbackBySearch();
    }, [filter.search]);
    useEffect(function () {
        if (offset > 0) {
            fetchEquipmentsCallback();
        }
    }, [offset]);
    useEffect(function () {
        fetchEquipmentsCallback();
    }, __spreadArray(__spreadArray([], deps, true), [
        filter.location_id,
        filter.in_action_list,
        filter.in_bad_list,
        filter.in_watchlist,
        filter.in_pre_ai_list,
        filter.in_detached_list,
        filter.not_installed,
        filter.with_tachometer,
        filter.distance,
        filter.filter_by_installation_point,
    ], false));
    useEffect(function () {
        if (installationPoint.id) {
            updateUrl(prefix, installationPoint, overlayInstallationPoints, prevInstallationPoint);
            trackEvent({
                category: 'Charts',
                action: 'View',
                name: "".concat(installationPoint.id, " - ").concat(installationPoint.name),
                href: window.location.href,
            });
        }
    }, [overlayInstallationPoints]);
    var onAllInstallationPointsOverlayUncheck = useCallback(function (ev, equipment) {
        ev.preventDefault();
        ev.stopPropagation();
        abortOverlayRequests();
        setStatesInstallationPointStore({
            overlayInstallationPoints: overlayInstallationPoints.filter(function (overlayInstallationPoint) { return +overlayInstallationPoint.equipment_id !== +equipment.id; }),
        });
    }, [overlayInstallationPoints]);
    return (_jsx(Scrollbars, { className: "left-equipment-scrollbar", onUpdate: function (values) {
            return onScroll(values, isLoading, lastReceivedEquipmentsCount, limit, function () {
                return onInfiniteScroll(offset + limit);
            });
        }, renderThumbVertical: function (_a) {
            var style = _a.style, props = __rest(_a, ["style"]);
            return (_jsx("div", __assign({ className: "thumb thumb-vertical", style: __assign({}, style) }, props)));
        }, children: _jsxs("div", { className: "equipment-list-container", children: [isLoading ? (_jsx("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        zIndex: 3,
                        backdropFilter: 'blur(2px)',
                        width: '300px',
                        height: 'calc(100vh - 100px)',
                    }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), equipments.map(function (equipment, index) { return (_jsx(Fragment, { children: _jsx(EquipmentRow, { equipment: equipment, equipmentTitle: equipmentTitle, equipmentName: equipmentName, index: index, onAllInstallationPointsOverlayUncheck: onAllInstallationPointsOverlayUncheck, onOverlayCheck: onOverlayCheck, setFilter: setFilter }) }, "equipment_".concat(equipment.id, "_").concat(index))); }), !isLoading && equipments.length === 0 && (_jsx("div", { className: "empty-equipments-label mt-2", children: "No tracking components found" }))] }) }));
};
export default memo(LeftEquipmentList);
