import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
var useQuery = useUntypedQuery;
export var PMNotesQueryKey = function (_a) {
    var customerId = _a.customerId;
    return ['PM-notes', { customerId: customerId }];
};
export var PMNotesQueryFn = function (_a) {
    var customerId = _a.customerId;
    return Api.get("all-analytics/customer/".concat(customerId, "/note"));
};
export var usePMNotesQuery = function (_a) {
    var customerId = _a.customerId;
    return useQuery({
        queryKey: PMNotesQueryKey({ customerId: customerId }),
        queryFn: function () { return PMNotesQueryFn({ customerId: customerId }); },
        select: function (_a) {
            var note = _a.note;
            return note;
        },
    });
};
