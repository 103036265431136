var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../../api/api';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { getCoreUrl } from '../../../helper/commonHelper';
import { selectPageDataTable, selectPageSizeDataTable, selectSearchDataTable, selectSelectedInstallationPointIdDataTable, selectSortDataTable, } from '../../../store/analystNotes/useAnalystNotesDataTableStore';
export var analystNotesListKey = function (installationPointId, additionOptions, sort, search, page, pageSize) { return ['analystNotesList', installationPointId, additionOptions, sort, search, page, pageSize]; };
var analystNotesListFn = function (coreLink, installationPointId, additionOptions, config, sort, search, page, pageSize, isStaticTable) {
    return Api.get("".concat(coreLink, "/analyst-notes"), {
        query: __assign({ installation_point_id: installationPointId, pagination: isStaticTable
                ? null
                : __assign(__assign({}, config.pagination), { page: page, pageSize: pageSize }), sort: isStaticTable ? null : sort, search: isStaticTable ? null : search }, additionOptions),
    });
};
export var useAnalystNotesListQuery = function (_a, isStaticTable) {
    var installationPointId = _a.installationPointId, config = _a.config, additionOptions = _a.additionOptions;
    if (isStaticTable === void 0) { isStaticTable = false; }
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var search = selectSearchDataTable();
    var page = selectPageDataTable();
    var pageSize = selectPageSizeDataTable();
    var sort = selectSortDataTable();
    var selectedInstallationPointId = selectSelectedInstallationPointIdDataTable();
    return useQuery({
        queryKey: analystNotesListKey(selectedInstallationPointId !== null && selectedInstallationPointId !== void 0 ? selectedInstallationPointId : installationPointId, additionOptions, sort, search, page, pageSize),
        queryFn: function () {
            return analystNotesListFn(getCoreUrl(selectedSystemType), selectedInstallationPointId !== null && selectedInstallationPointId !== void 0 ? selectedInstallationPointId : installationPointId, additionOptions, config, sort, search, page, pageSize, isStaticTable);
        },
        select: function (_a) {
            var data = _a.data, total = _a.total;
            return {
                config: __assign(__assign({}, config), { data: data, pagination: __assign(__assign({}, config.pagination), { total: total, page: page, pageSize: pageSize }), search: search, sort: sort }),
            };
        },
        placeholderData: function (previousData) {
            return (previousData !== null && previousData !== void 0 ? previousData : {
                data: [],
                total: 0,
            });
        },
    });
};
