import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
var useQuery = useUntypedQuery;
export var SSDNotesQueryKey = function (_a) {
    var equipmentId = _a.equipmentId;
    return [
        'SSD-notes',
        { equipmentId: equipmentId },
    ];
};
export var SSDNotesQueryFn = function (_a) {
    var equipmentId = _a.equipmentId;
    return Api.get("all-analytics/equipment/".concat(equipmentId, "/notes"));
};
export var useSSDNotesQuery = function (_a) {
    var equipmentId = _a.equipmentId;
    return useQuery({
        queryKey: SSDNotesQueryKey({ equipmentId: equipmentId }),
        queryFn: function () { return SSDNotesQueryFn({ equipmentId: equipmentId }); },
        placeholderData: [],
    });
};
