import { jsx as _jsx } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep } from 'lodash';
import React, { memo } from 'react';
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
import { Button } from 'reactstrap';
var FullscreenButton = function (_a) {
    var onClickFullscreen = _a.onClickFullscreen, isVisibleFullscreenChart = _a.isVisibleFullscreenChart, width = _a.width, isDisabled = _a.isDisabled;
    return (_jsx("div", { className: "chart-btn", children: _jsx(Button, { className: "fullscreen-btn chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", onClick: onClickFullscreen, style: { width: width }, disabled: isDisabled, children: isVisibleFullscreenChart ? _jsx(BsArrowsAngleContract, {}) : _jsx(BsArrowsAngleExpand, {}) }) }));
};
export default memo(FullscreenButton);
export var onToggleFullscreen = function (dispatch, isVisibleFullscreenChart, chartRef) {
    dispatch({
        type: 'setState',
        state: {
            isFFDetection: false,
            FFDetectionPoints: [],
            isVisibleFullscreenChart: isVisibleFullscreenChart,
            fftFullscreenUserOptions: chartRef ? _cloneDeep(chartRef.current.chart.userOptions) : {},
        },
    });
};
