export var getFFDetectionConfig = function (FFDetectionPoints) {
    if (FFDetectionPoints.length === 0) {
        return [];
    }
    var FFDetectionPointsPlotLines = [];
    FFDetectionPoints.forEach(function (x, index) {
        FFDetectionPointsPlotLines.push({
            id: x + index,
            type: 'FFDetection',
            width: 1,
            baseValue: x,
            value: x,
            dashStyle: 'Dash',
            color: '#708090',
            zIndex: 10,
            label: {
                // @ts-ignore
                useHTML: true,
                style: {
                    color: '#FFFFFF',
                    backgroundColor: '#708090',
                    marginLeft: '5px',
                    padding: '0 2px',
                    'font-size': '11px',
                    'z-index': 12,
                    'min-width': '35px',
                    'text-align': 'center',
                },
                // @ts-ignore
                rotation: 90,
                x: -5,
                y: 0,
                verticalAlign: 'top',
                text: "<span class=\"bearing-plotline-badge ".concat(x, "_").concat(index, "\">C").concat(index + 1, "</span>"),
            },
        });
    });
    return FFDetectionPointsPlotLines;
};
