import { jsx as _jsx } from "react/jsx-runtime";
import { DateTimeLabel } from '../../../../widgets/common';
export var dataTableConfig = {
    columns: [
        {
            name: 'equipment_name',
            title: 'Equipment name',
            sortable: false,
        },
        {
            name: 'installation_point_name',
            title: 'Installation point name',
            sortable: false,
        },
        {
            name: 'created_at',
            title: 'Date created at',
            sortable: false,
            component: function (_a) {
                var created_at = _a.created_at;
                return _jsx(DateTimeLabel, { dateTime: created_at });
            },
        },
        {
            name: 'url',
            title: '',
            sortable: false,
            component: function (_a) {
                var url = _a.url;
                return (_jsx("a", { href: url, download: true, target: "_blank", children: "Download" }));
            },
        },
    ],
    data: [],
    loader: false,
    hideTotalLabel: true,
    pagination: {
        disabled: true,
    },
    search: '',
    disabledSearch: true,
};
