export var AVG_FILTER_FIELDS = [
    {
        name: 'in_alarm',
        title: 'In alarm',
    },
    {
        name: 'hide_on_bed_ai',
        title: 'Hide if AI exists on Bed',
    },
    {
        name: 'hide_ai',
        title: 'Hide existing AI',
    },
    {
        name: 'hide_bs',
        title: 'Hide Bad SensorsByEquipment',
    },
    {
        name: 'last_48_hours',
        title: 'Max Value Time (48h)',
    },
];
export var ANALYST_NOTES_TAGS = [
    {
        name: 'customer',
        id: 1,
    },
    {
        name: 'facility',
        id: 2,
    },
    {
        name: 'location',
        id: 3,
    },
    {
        name: 'equipment',
        id: 4,
    },
    {
        name: 'installation point',
        id: 5,
    },
    {
        name: 'component',
        id: 6,
    },
    {
        name: 'sensor',
        id: 7,
    },
    {
        name: 'node',
        id: 8,
    },
    {
        name: 'reading type',
        id: 9,
    },
    {
        name: 'alert levels',
        id: 10,
    },
];
export var DASHBOARD_CARD_TITLES = {
    OldPointsVerification: 'Old Points Verification',
    CustomerCreatedAi: 'Action Items - Customer Created',
    DeclinedReportAi: 'Declined Reports',
    PreActionItems: 'Pre-Action Items',
    AiWaiting: 'Action Items - Waiting',
    AutoAi: 'Action Items - Auto Action Items',
    WatchList: 'Watchlist',
    SubmittedReportAi: 'Action Items - Completion Report Submitted',
    TroubleshootingWaitingAi: 'Action Items - Troubleshooting Hold - Waiting',
    WatcherStatistic: 'Statistic analysts',
    TotalWatcherStatistic: 'Total statistic',
    UserActivity: 'User activity',
    IncreasedSeverityAi: 'Increased Severity Ai',
    IncreasedSeverityPreAi: 'Increased Severity Pre-Ai',
};
export var DEFAULT_DASHBOARD_CARD = [
    {
        w: 4,
        h: 6,
        x: 0,
        y: 0,
        i: 'OldPointsVerification',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 0,
        y: 6,
        i: 'CustomerCreatedAi',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 4,
        y: 6,
        i: 'DeclinedReportAi',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 0,
        y: 12,
        i: 'PreActionItems',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 4,
        y: 12,
        i: 'AiWaiting',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 0,
        y: 18,
        i: 'WatchList',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 4,
        y: 18,
        i: 'SubmittedReportAi',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 0,
        y: 24,
        i: 'AutoAi',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 4,
        y: 24,
        i: 'TroubleshootingWaitingAi',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 0,
        y: 30,
        i: 'IncreasedSeverityAi',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 4,
        h: 6,
        x: 4,
        y: 24,
        i: 'IncreasedSeverityPreAi',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 8,
        h: 6,
        x: 0,
        y: 34,
        i: 'WatcherStatistic',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 8,
        h: 2,
        x: 0,
        y: 40,
        i: 'TotalWatcherStatistic',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
    {
        w: 8,
        h: 6,
        x: 0,
        y: 42,
        i: 'UserActivity',
        minW: 2,
        minH: 2,
        moved: false,
        static: false,
    },
];
export var COMPONENT_TYPE_COUPLING = 3;
export var COMPONENT_TYPE_BELT_AND_CHAINS = 4;
export var BAD_DATA_COMPONENT_TYPE_ID = 21;
export var BAD_DATA_RECOMMENDATION_ID = 113;
export var BOOLEAN_INSTALLATION_POINT_TYPE = 'boolean';
