import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { selectIsVisibleMachineInformationModal, useActionItemModalsActions, } from '../../store/AIPreAi/useActionItemModalsStore';
var MachineInformationOnAIModal = function (_a) {
    var notes = _a.notes, nameplates = _a.nameplates, oilReports = _a.oilReports;
    var setIsVisibleMachineInformationModal = useActionItemModalsActions().setIsVisibleMachineInformationModal;
    var isVisibleMachineInformationModal = selectIsVisibleMachineInformationModal();
    var tabs = [
        {
            component: notes,
            title: 'Notes',
        },
        {
            component: nameplates,
            title: 'Nameplates',
        },
        {
            component: oilReports,
            title: 'Oil Reports',
        },
    ];
    var _b = useState(tabs[0].title), activeTab = _b[0], setActiveTab = _b[1];
    var closeModal = function () {
        setIsVisibleMachineInformationModal(false);
    };
    return (_jsxs(Modal, { isOpen: isVisibleMachineInformationModal, toggle: closeModal, className: "modal-response", size: "xl", children: [_jsx(ModalHeader, { toggle: closeModal, children: "Machine Information" }), _jsxs(ModalBody, { children: [_jsx(Nav, { tabs: true, className: "pt-0", children: tabs.map(function (_a) {
                            var title = _a.title;
                            return (_jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === title, onClick: function () { return setActiveTab(title); }, children: title }) }, title));
                        }) }), _jsx(TabContent, { className: "pt-3", activeTab: activeTab, children: tabs.map(function (_a) {
                            var component = _a.component, title = _a.title;
                            return (_jsx(TabPane, { tabId: title, children: component }, title));
                        }) })] })] }));
};
export default memo(MachineInformationOnAIModal);
